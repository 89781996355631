import { Configuration } from "../../Ui/PrompConfiguration";
import { TemplateService } from "../Services/TemplateService";
import { TemplateLiterals } from "../models/TemplateLiterals";

//This usecase calls the servicemethod to generate a whole Template
export class GenerateTemplate {
  constructor(private readonly templateService: TemplateService) {}
  async invoke(
    config: Configuration,
    variables: string[],
    literals: TemplateLiterals
  ): Promise<{
    input: {
      variables: string[];
      config: Configuration;
      literals: TemplateLiterals;
    };
    aiOutput: string;
  }> {
    return await this.templateService.generateTemplate(
      config,
      variables,
      literals
    );
  }
}
