import React, {useEffect} from 'react';
import './App.css';
import { Dashbord } from './Ui/Dashbord';
import {useAppDispatch} from "./hooks";
import {TemplateLiterals} from "./core/models/TemplateLiterals";
import { setEventId, setLiterals} from "./State/templateState";

function App() {

  // Get a reference to the Redux dispatch function
  const dispatch = useAppDispatch();

  // Use useEffect to run code after the component has mounted
  useEffect(() => {

    // Parse data from the query parameters in the URL
    const searchParams = new URLSearchParams(window.location.search);
    const data : TemplateLiterals = JSON.parse(searchParams.get('data') || '{}');

    // Log the parsed data to the console
    console.log(data)

    // Extract eventId from the parsed data
    const eventId : number = JSON.parse(searchParams.get('data') || '{}').eventId;

    // Dispatch actions to update Redux state with the parsed data
    dispatch(setLiterals(data))
    dispatch(setEventId(eventId))
  },[]) // The empty dependency array ensures that this effect runs only once after mount

  // Render the main component with the parsed data
  return (
    <div className="App">
      <Dashbord></Dashbord>
    </div>
  );
}
export default App;
