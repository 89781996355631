import {useAppDispatch, useAppSelector} from "../hooks";
import {selectTemplate, setLoadingParagraph, updateParagraph, updateTemplate} from "../State/templateState";
import {Button, Loader} from "@mantine/core";
import {useEffect, useRef, useState} from "react";
import {
    TemplateParagraph
} from "../core/models/Template";
import {notifications} from "@mantine/notifications";

//In sum this is the code for the Textinputfields

export const TemplateController = () => {

    //be able to read the redux value
    const state = useAppSelector(selectTemplate)

    return <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderLeft: "1px solid lightgrey",
        paddingLeft: "20px",
        width: "100%",
        height: "65vh",
        overflow: "scroll",
        paddingRight: "20px"
    }}>
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "50px",

        }}>
            <h4>Vorlage</h4>

            {
                //render the loader if there are open ai requests
                state.status === "loading" && <Loader></Loader>
            }
        </div>
        {state.paragraphs != null && Object.entries(state.paragraphs!).map(([key, paragraph]) => (

            //render a Textinput field for every Paragraph of a template. Reeeeeaaaally custom so dont change it if not neccesary
            <>
                <EditParagraph paragraphKey={key} rawValue={paragraph.rawValue} htmlValue={paragraph.htmlValue} literals={paragraph.literals} variables={paragraph.variables}></EditParagraph>
            </>
        ))}

    </div>
}
export const EditParagraph = ( inputParagraph : TemplateParagraph) => {

    // be able to update the redux state
    const dispatch = useAppDispatch();

    //be able to read the redux sate
    const state = useAppSelector(selectTemplate)

    //not really sure what this one is doing but kind of afraid to touch it
    const [prev, setPrev] = useState(inputParagraph.htmlValue)

    //tracks for changes in the paragraph values
    const [touched, setTouched] = useState(false)

    //the ref for that specifc textinput field
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        // add an eventlistener to the text Paragraph so its able to handle user input
        const divElement = divRef.current;
        if (divElement) {
            divElement.innerHTML = inputParagraph.htmlValue
            divElement.addEventListener('input', handleInput);
        }

        //remove the eventlistener once the user is done?
        return () => {
            if (divElement) {
                divElement.removeEventListener('input', handleInput);
            }
        };
    }, []);

    const handleInput = () => {
        const divElement = divRef.current;

        if (divElement) {
            let flag = true;
            //do it for all variable placeholder, even tho in our use case there is only one. But in the future there might be more ;)
            for (const variable of inputParagraph.variables) {

                //this one checks for invalid changes. You arent allowed to change variable values
                if (!divElement.innerText.includes(variable.placeholder)) {
                    flag = false;

                    //show the user whats up
                    notifications.show({
                        title: 'Man kann die Sponsor Platzhalter nicht bearbeiten',
                        message: 'Ein Programm setzt dort später den echten Namen ein',
                    });

                    setPrev(prevValue => {
                        divElement.innerHTML = prevValue; // Reset with the correct prevValue
                        return prevValue; // Maintain the previous valid state
                    });
                }
            }

            if (flag) {
                setPrev(divElement.innerHTML);
                setTouched(true)
            }
        }
    };

    return (
        <>
            <div style={{display: "flex", justifyContent: "flex-start", gap: "15px", alignItems: "center"}}>
                <h4 style={{marginBottom: "0px", marginTop: "10px"}}>
                    { //creates the correct Header for the Text Edit fields. Not the most beautiful code but its working
                        inputParagraph.paragraphKey === "salutationFemale" ? "Weibliche Anrede" :
                            inputParagraph.paragraphKey === "salutationMale" ? "Männliche Anrede" :
                                inputParagraph.paragraphKey === "salutationNone" ? "Keine Anrede" :
                                    inputParagraph.paragraphKey === "categories" ? "Kategorien" :
                                        inputParagraph.paragraphKey === "eventInformation" ? "Event Informationen":
                                            inputParagraph.paragraphKey === "thanks" ? "Danksagung":
                                                inputParagraph.paragraphKey === "roundUp" ? "Aufrunden Appell":
                                                    inputParagraph.paragraphKey === "payment" ? "Bezahl Appell":
                                                        inputParagraph.paragraphKey === "greeting" ? "Schluss": ""
                    }
                </h4>
                { //if an Textinput field was changed the "saving" button will be rendered for that specific inputfield
                    touched && <Button style={{height: "20px"}} onClick={() => {
                        dispatch(updateParagraph({
                            paragraphKey: inputParagraph.paragraphKey,
                            rawValue: document.getElementById(inputParagraph.paragraphKey)!.innerText,
                            htmlValue: document.getElementById(inputParagraph.paragraphKey)!.innerHTML,
                            variables: inputParagraph.variables,
                            literals: inputParagraph.literals
                        }))
                        setTouched(false)
                    }}>Speichern</Button>
                }
                { //if an Textinput field was changed the "cancel" button will be rendered for that specific inputfield
                    touched && <Button style={{height: "20px"}} color={"red"} onClick={() => {
                        const divElement = divRef.current;
                        divElement!.innerHTML = inputParagraph.htmlValue
                        //dispatch(updateParagraph(inputParagraph))
                        setTouched(false)
                    }}>Abbrechen</Button>
                }

            </div>
            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "15px", width: "100%"}}>
                {
                    //Regenerate Button to generate a new ai value for that specific text paragraph
                    state.status !== "loading" && state.paragraphLoading === "" && <Button
                        size="xs"
                        style={{paddingLeft: "10px", paddingTop: "5px",paddingBottom: "5px", paddingRight: "10px", height: "30px"}}
                        onClick={ () => {
                            dispatch(setLoadingParagraph(inputParagraph.paragraphKey))
                            dispatch(
                                updateTemplate({
                                    templateContent : state.aioutput!,
                                    literals: state.literals!,
                                    config: state.config!,
                                    paragraphKey : inputParagraph.paragraphKey
                                })
                            );
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="20px" height="20px"><g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.33333,5.33333)"><path d="M13,13c0,-3.3 2.7,-6 6,-6h10c3.3,0 6,2.7 6,6h4c0,-5.5 -4.5,-10 -10,-10h-10c-5.5,0 -10,4.5 -10,10v11.2h4z"></path><path d="M4.6,22l6.4,8.4l6.4,-8.4z"></path><g><path d="M35,35c0,3.3 -2.7,6 -6,6h-10c-3.3,0 -6,-2.7 -6,-6h-4c0,5.5 4.5,10 10,10h10c5.5,0 10,-4.5 10,-10v-12h-4z"></path><path d="M30.6,26l6.4,-8.4l6.4,8.4z"></path></g></g></g></svg>
                    </Button>
                }
                {
                    //Disabled Regenerate Button when there is an open ai request (disabled for all text paragraphs, even if only one is being generated)
                    state.status === "loading" && <Button
                        disabled
                        size="xs"
                        style={{paddingLeft: "10px", paddingTop: "5px",paddingBottom: "5px", paddingRight: "10px", height: "30px"}}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="20px" height="20px"><g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.33333,5.33333)"><path d="M13,13c0,-3.3 2.7,-6 6,-6h10c3.3,0 6,2.7 6,6h4c0,-5.5 -4.5,-10 -10,-10h-10c-5.5,0 -10,4.5 -10,10v11.2h4z"></path><path d="M4.6,22l6.4,8.4l6.4,-8.4z"></path><g><path d="M35,35c0,3.3 -2.7,6 -6,6h-10c-3.3,0 -6,-2.7 -6,-6h-4c0,5.5 4.5,10 10,10h10c5.5,0 10,-4.5 10,-10v-12h-4z"></path><path d="M30.6,26l6.4,-8.4l6.4,8.4z"></path></g></g></g></svg>
                    </Button>
                }
                {
                    //this one is the actual textinput field. custom so dont change it if you dont really have to. took 2 weeks to make this whole thing work.
                }
                <div
                    id={inputParagraph.paragraphKey}
                    style={{textAlign: "left", background: state.paragraphLoading !== inputParagraph.paragraphKey ? "white" : "#F2F3F5", padding: "5px", width: "100%", border: "1px solid lightgrey"}}
                    ref={divRef}
                    contentEditable={state.paragraphLoading !== inputParagraph.paragraphKey }
                    dangerouslySetInnerHTML={{__html: inputParagraph.htmlValue}}
                />
            </div>


        </>

    );
};

//this function creates a html string for Textparagraphs, mounting all the literal and variable values into a valid html string
export function convertToHtml(template: TemplateParagraph) : string {

    //initialize the return value
    let result = template.rawValue;

    // Replace literals with editable span
    for (const literal of template.literals){
        result = replaceWithEditSpan(result, literal.value)
        console.log("replaced " + literal.value)

    }

    // Replace variables with NON-editable span
    for (const variable of template.variables){
        result = replaceWithNonEditSpan(result, variable.placeholder)
        console.log("replaced " + variable.placeholder)
    }

    //log the new beautiful html string for debugging purposes
    console.log(result)

    //return the updated return value
    return result
}

//this function creates the green, editable spans used for literal values
function replaceWithEditSpan(inputString: string, substring: string): string {
    const replacedString = inputString.replace("[" + substring + "]", ` <span style='padding-left:10px; padding-right:10px; padding-top: 1px;padding-bottom: 1px;background-color: #7CAA52;  border-radius: 10px; color: white;'>${substring}</span> `);
    return replacedString;
}

//this functions creates the NON-editable, black spans used for variable values
function replaceWithNonEditSpan(inputString: string, substring: string): string {
    const replacedString = inputString.replace("[" + substring + "]", `<span contenteditable=false style='background-color: #222327; padding-left:10px; padding-right:10px; padding-top: 1px;padding-bottom: 1px; border-radius: 10px; color: white;'>${substring}</span>`);
    return replacedString;
}
