import { configureStore } from "@reduxjs/toolkit";

import {templateSlice} from "./State/templateState";




const store = configureStore(
    {
        reducer: {
            templateState: templateSlice.reducer,
        }
    }
)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store