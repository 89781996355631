import {selectTemplate} from "../State/templateState";
import {useAppSelector} from "../hooks";


//This component shows all the literalData that where passed in the system via the entry url
export const LiteralInput = () => {

    //be able to read the redux state
    const stateTemplate = useAppSelector(selectTemplate);

    //display all the literals
    return <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%"}}>
            <h4 style={{paddingLeft: "20px"}}>
                Event Daten
            </h4>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "column", paddingLeft: "20px", alignItems: "flex-start"}}>
                    <h4>
                        Event Name
                    </h4>
                    <p>
                        {stateTemplate.literals?.event_name}
                    </p>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <h4>
                        Kategorien
                    </h4>
                    {stateTemplate.literals?.event_categories && stateTemplate.literals.event_categories.map(
                        (category, index) => (
                            <p key={index}>{category}</p>
                        )
                    )}

                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <h4>
                        Spendenzwecke
                    </h4>

                    {stateTemplate.literals?.event_DonationPurpose && stateTemplate.literals.event_DonationPurpose.map(
                        (purpose, index) => (
                            <p key={index}>{purpose}</p>
                        )
                    )}

                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <h4>
                        Gesamterlös
                    </h4>
                    <p>
                        {stateTemplate.literals?.event_total_amount}
                    </p>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <h4>
                        Gesamte Strecke
                    </h4>
                    <p>
                        {stateTemplate.literals?.event_total_distance}
                    </p>
                </div>
                <div style={{display: "flex", flexDirection: "column", paddingRight: "20px", alignItems: "flex-start"}}>
                    <h4>
                        Gesamt Teilnehmer
                    </h4>
                    <p>
                        {stateTemplate.literals?.event_total_participants}
                    </p>
                </div>
            </div>
        </div>
    </div>
}