import {Button} from "@mantine/core";
import {useAppSelector} from "../hooks";
import {selectTemplate} from "../State/templateState";
import {MoveFrontendBaseUrl} from "../Arch/urls";

//This component displays the Pdf from the passed in pdfUrl and the Backnavigation button
export const PdfPreview = ({pdfUrl} : {pdfUrl: string}) => {

    const state = useAppSelector(selectTemplate)

    //Backnavigation to OG Charity Move PLattform Frontend
    function handle () {
        const requestParams = {...state.aioutput, eventId: state.eventId}
        console.log(requestParams)

        const url = MoveFrontendBaseUrl + "invoicetemplate?templateContent=" + JSON.stringify(requestParams)
        window.location.href = url
    }

    return <div>
        <div style={{width: "100%", display: "flex", justifyContent: "flex-start", paddingLeft: "15px", gap: "15px", alignItems: "center"}}>
            <h4>Vorlagen Vorschau</h4>
            {pdfUrl !== "" && <Button onClick={handle}>zurück zu Charity Move</Button>}
        </div>

        <div style={{display: "flex", justifyContent: "flex-start", padding: "15px"}}>
            {pdfUrl !== "" && <iframe
                title="PDF Viewer"
                src={pdfUrl}
                width="500px"
                height="700px"
                style={{ border: '1px solid #ccc' }}
            />}

            {
                pdfUrl === "" && <p>Loading PDF...</p>
            }
        </div>
    </div>
}

