import { Configuration } from "../../Ui/PrompConfiguration";
import { TemplateService } from "../Services/TemplateService";
import { TemplateLiterals } from "../models/TemplateLiterals";
import {Template} from "../models/Template";


//this usecase calls the service method to update a TextParagraph
export class UpdateTemplate {
  constructor(private readonly updateService: TemplateService) {}
  async invoke(
      templateContent : Template,
      literals: TemplateLiterals,
      config: Configuration,
      paragraphKey : string
  ): Promise<{
    output: {
      paragraph : string,
      paragraphKey : string
    };
  }> {
    return await this.updateService.updateTemplate(
        templateContent,
        literals,
        config,
        paragraphKey
    );
  }
}
