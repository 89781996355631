import { MoveAIApiClient } from "../Arch/ApiClient";

import { Configuration } from "../Ui/PrompConfiguration";
import { TemplateService } from "../core/Services/TemplateService";
import { TemplateLiterals } from "../core/models/TemplateLiterals";
import {Template} from "../core/models/Template";
import {AiServiceBaseUrl} from "../Arch/urls";

export class TemplateRepository implements TemplateService {

  //initialize an Apiclient to talk to the moveAiBackend
  apiClient = new MoveAIApiClient();

  //This function updates a TemplateParagraph
  async updateTemplate(
      templateContent : Template,
      literals: TemplateLiterals,
      config: Configuration,
      paragraphKey : string
  ): Promise<{
    output: {
      paragraph : string,
      paragraphKey : string
    };
  }> {
    //change later to the updateTemplate
    const url = AiServiceBaseUrl + "Events/generatePartial";

    const header = {
      "Content-Type": "application/json",
    };
    console.log(config)
    const body = {
      "templateContent" : templateContent,
      "literals" : literals,
      "config" : config,
      "paragraphKey" : paragraphKey
    };
    const data = await this.apiClient.post(url, header, body);
    console.log(data)
    if(data.errorUnexpected || data.KeyError){
      throw Error()
    }
    return data
  }


  //this function generates a whole Template
  async generateTemplate(
    config: Configuration,
    variables: string[],
    literals: TemplateLiterals
  ): Promise<{
    input: {
      variables: string[];
      config: Configuration;
      literals: TemplateLiterals;
    };
    aiOutput: string;
  }> {
    const url = AiServiceBaseUrl + "Events/generateTemplate";

    const header = {
      "Content-Type": "application/json",
    };
    const body = {
      input: {
        config: config,
        variables: variables,
        literals: literals,
      },
    };
    const data = await this.apiClient.post(url, header, body);
    if(data.error){
      throw Error()
    }
    return data
  }
}
