
//This is a abstract Apiclient containing all rest methods
export interface ApiClient {
    get(url: string, header: {}): Promise<any>;

    post(url: string, header: {}, body: {},
    ): Promise<any>;

    put(url: string, header: {}, body: {},
    ): Promise<any>;

    delete(url: string, header: {}): Promise<any>
}


//this is the implemented Apiclient containing get and post methods. In this project we didnt need put and delete so these functions arent implemented
export class MoveAIApiClient implements ApiClient {
    async get(url: string, header: {}): Promise<any> {
        const result = await fetch(url, {
            method: "GET",
            headers: header
        })
        const resultJson = await result.json()
        console.log(resultJson)
        return resultJson
    }

    async post(url: string, header: {}, body: {}, mode:boolean=false): Promise<any> {
        if(!mode){
            const result = await fetch(url, {
                method: "POST",
                headers: header,
                body: JSON.stringify(body)
            })
            const resultJson = await result.json()
            console.log(resultJson)
            return resultJson
        }
        if(mode){
            const result = await fetch(url, {
                method: "POST",
                headers: header,
                mode: "cors",
                body: JSON.stringify(body)
            })
            console.log(result)
            const resultJson = await result.json()
            console.log(resultJson)
            return resultJson
        }

    }

    put(url: string, header: {}, body: {}): Promise<any> {
        throw new Error("Method not implemented.");
    }

    delete(url: string, header: {}): Promise<any> {
        throw new Error("Method not implemented.");
    }

}
     