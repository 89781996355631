//this is the Url wich needs to be used when deployed to the digitial ocean test instance
//export const AiServiceBaseUrl: string = "https://ai-api.test.charity-move.de/"

//this is the url that needs to be active when developing on a local system having the moveai backend running on port 5000


//this is the Charity Move backend url used for pdf generation
const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

export const AiServiceBaseUrl : string = isLocal ? "http://127.0.0.1:5000/" : "https://ai-api.test.charity-move.de/";
export const MoveBackendBaseUrl: string = isLocal ? "http://localhost:8080/" : "https://api.test.charity-move.de/";
export const MoveFrontendBaseUrl: string = isLocal ? "http://localhost:3001/": "https://app.test.charity-move.de/";