import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {createTheme, MantineProvider} from '@mantine/core';
import store from './Store';
import { Provider } from 'react-redux';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import {Notifications} from "@mantine/notifications";
import {
    BrowserRouter as Router,
    createBrowserRouter,
    Route,
    RouterProvider,
    useLocation,
    useParams
} from 'react-router-dom';
import {TemplateLiterals} from "./core/models/TemplateLiterals";



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    /** Put your mantine theme override here */
});

const router = createBrowserRouter([
    {
        path: "/", //this route exists for testing purposes. if you dont want to log into charity move to create a valid entry url u can use the url rendered in this component
        element: <div>
            <h4>For Testing purposes!</h4>
            <p>{"http://localhost:3000/App?data={%22eventId%22:%2249552%22,%22event_name%22:%22Clausthal%20Run%22,%22event_total_amount%22:1000,%22event_total_distance%22:50,%22event_categories%22:[%22Studenten%22,%22Professoren%22],%22event_DonationPurpose%22:[%22ChatGptPremium%22,%22LucidChartPremium%22],%22event_total_participants%22:200}"}</p>
        </div>, //U can change the values but not the keys to test out different scenarios

    },
    {
        path: "/App", // this is the main route, used as the entry url from charity move. Only change when the whole deerstone system is updated.
        element: <App></App>,
    },

]);




root.render(
  <React.StrictMode>
    <Provider store={store}>
        <MantineProvider theme={theme}>
            <Notifications position="top-right"/>
            <RouterProvider router={router} />
        </MantineProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
