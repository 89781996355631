import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../Store"
import {GenerateTemplate} from "../core/UseCases/generateTemplate";
import {Configuration} from "../Ui/PrompConfiguration";
import { TemplateParagraph, Template, HtmlParagraphs} from "../core/models/Template";
import {TemplateLiterals} from "../core/models/TemplateLiterals";
import {TemplateRepository} from "../Data/TemplateRepository";
import {convertToHtml} from "../Ui/TemplateController";
import {UpdateTemplate} from "../core/UseCases/updateTemplate";
import {notifications} from "@mantine/notifications";


//This is the definition of the Redux State Slice Object
export interface TemplateState
{
    config: null | Configuration,
    variables: null | string[],
    literals: null | TemplateLiterals,
    aioutput : null | Template,
    paragraphs: null | HtmlParagraphs,
    status : "idle" | "loading" | "failed",
    paragraphLoading : string,
    pdf: any //Not used yet because the state is handles localy. Could be implemented here if its needed gobaly
    eventId: number | null
}

//This is the .....trommelwirbel......Initial State of the reduxSlice
const initialState : TemplateState= {
    config: null,
    literals: null,
    variables: null,
    aioutput: null,
    paragraphs: null,
    status: "idle",
    paragraphLoading : "",
    pdf : null,
    eventId: null
}

//Initialize the TemplateRepository and pass it into the Usecases
const templateRepository = new TemplateRepository();
const generateTemplateUseCase = new GenerateTemplate(templateRepository);
const updateTemplateUseCase = new UpdateTemplate(templateRepository);

//This is the reducer wich generates a whole template
export const generateTemplate = createAsyncThunk(
  "generateTemplate",
  async ({
    config,
    variables,
    literals,
  }: {
    config: Configuration;
    variables: string[];
    literals: TemplateLiterals;
  }) => {
    console.log("---------__!!_-!!!!!!!");
    return await generateTemplateUseCase.invoke(config, variables, literals);
  }
);

//This could be used if the pdf generation should be made to a global state. Right now its only local state, so this component has no real use yet.
export const getPdf = createAsyncThunk(
    "generateTemplate",
    async ({
               aiOutput
           }: {
        aiOutput: Template;
    }) => {
        console.log("---------__!!_-!!!!!!!");
        return await null
    }
);

//this is the reducer that updates a Template
export const updateTemplate = createAsyncThunk(
    "updateTemplate",
    async ({
               templateContent,
               literals,
               config,
               paragraphKey
           }: {
        templateContent : Template,
        literals: TemplateLiterals,
        config: Configuration,
        paragraphKey : string
    }) => {

        return await updateTemplateUseCase.invoke(
            templateContent,
            literals,
            config,
            paragraphKey);
    }
);

//this is the definition of the redux Slice used in this application
export const templateSlice =createSlice(
    {
        name : "templateSlice",
        initialState,
        reducers: {
            // sync reducer to update the template
            setTemplateForm: (state, action: {payload: Template}) => {
                state.aioutput = action.payload;
            },
            // sync reducer to set the configutation object
            setConfigGlobal: (state, action: {payload: Configuration}) => {
                state.config = action.payload;
            },
            // sync reducer to set the eventId
            setEventId: (state, action: {payload: number}) => {
                state.eventId = action.payload;
            },
            // sync reducer to make editing possible
            updateParagraph: (state, action: {payload: TemplateParagraph}) => {
                console.log(action.payload)

                if(action.payload.paragraphKey === "salutationFemale"){
                    // updating the text editing paragraph used for custom html
                    state.paragraphs!.salutationFemale = action.payload
                    // updating the template paragraph used for communication to other systems (real value)
                    state.aioutput!.salutationFemale = action.payload.rawValue
                }
                //same as above is the rule for the whole reducer
                if(action.payload.paragraphKey === "salutationMale"){
                    state.paragraphs!.salutationMale = action.payload
                    state.aioutput!.salutationMale = action.payload.rawValue
                }
                if(action.payload.paragraphKey === "salutationNone"){
                    state.paragraphs!.salutationNone = action.payload
                    state.aioutput!.salutationNone = action.payload.rawValue
                }
                if(action.payload.paragraphKey === "categories"){
                    state.paragraphs!.categories = action.payload
                    state.aioutput!.categories = action.payload.rawValue
                }
                if(action.payload.paragraphKey === "eventInformation"){
                    state.paragraphs!.eventInformation = action.payload
                    state.aioutput!.eventInformation = action.payload.rawValue
                }
                if(action.payload.paragraphKey === "thanks"){
                    state.paragraphs!.thanks = action.payload
                    state.aioutput!.thanks = action.payload.rawValue
                }
                if(action.payload.paragraphKey === "roundUp"){
                    state.paragraphs!.roundUp = action.payload
                    state.aioutput!.roundUp = action.payload.rawValue
                }
                if(action.payload.paragraphKey === "payment"){
                    state.paragraphs!.payment = action.payload
                    state.aioutput!.payment = action.payload.rawValue
                }
                if(action.payload.paragraphKey === "greeting"){
                    state.paragraphs!.greeting = action.payload
                    state.aioutput!.greeting = action.payload.rawValue
                }

            },
            //sync reducer to set the literals
            setLiterals: (state, action: {payload: TemplateLiterals}) => {
                state.literals = action.payload;
            },
            //sync reducer to make the ui able to react to different loading scenarios
            setLoadingParagraph: (state, action: {payload: string}) => {
                state.paragraphLoading = action.payload
            }

        },
        extraReducers : (builder) =>
        {
            builder
                .addCase(updateTemplate.pending, (state) => {
                    state.status = "loading";
                })
                .addCase(updateTemplate.fulfilled, (state, action) => {
                    //only explained once so read carefully

                    //check wich part is gonna be updated
                    if(action.payload.output.paragraphKey == "salutationFemale"){

                        //log whats comming in for debugging purposes
                        console.log(action.payload)

                        //initialize a TemplateParagraph
                        const salutationFemale : TemplateParagraph = {
                            //set the key, depending on what the check above is telling you
                            paragraphKey: "salutationFemale",
                            //set the actual value coming back from the api ("[" and "]" will be around literals and variables)
                            rawValue: action.payload.output.paragraph,
                            //set the html value to an empty string
                            htmlValue: "",
                            //set this literals wich will be present in this paragraph (as many as you want are allowed)
                            literals: [

                            ],
                            //set the veriables wich will be present in this paragaph (as many as you want are allowed)
                            variables: [{
                                placeholder: "sponsor.name"
                            }]
                            //You can also combine literals and variables in one TemplateParagraph even tho its not initialized in our system
                        }

                        //set the htmlValue of the above initialized TemplateParagraph by passing in the object into a html conversion function wich returns the object with now the valid html string
                        salutationFemale.htmlValue = convertToHtml(salutationFemale)

                        //update the now complete Paragraph
                        state.paragraphs!.salutationFemale = salutationFemale

                        //remove the curly brackets for the Template that is used for communication with other systems.
                        const tempTempOutput = action.payload.output.paragraph
                        console.log(tempTempOutput)
                        tempTempOutput.replace("[", "")
                        tempTempOutput.replace("]", "")

                        // Update the value that is used for communication with other systems
                        state.aioutput!.salutationFemale = tempTempOutput
                    }

                    if(action.payload.output.paragraphKey == "salutationMale"){
                        console.log(action.payload)
                        const salutationMale : TemplateParagraph = {
                            paragraphKey: "salutationMale",
                            rawValue: action.payload.output.paragraph,
                            htmlValue: "",
                            literals: [

                            ],
                            variables: [{
                                placeholder: "sponsor.name"
                            }]
                        }
                        salutationMale.htmlValue = convertToHtml(salutationMale)
                        state.paragraphs!.salutationMale = salutationMale

                        const tempTempOutput = action.payload.output.paragraph
                        console.log(tempTempOutput)
                        tempTempOutput.replace("[", "")
                        tempTempOutput.replace("]", "")

                        state.aioutput!.salutationMale = tempTempOutput
                    }
                    if(action.payload.output.paragraphKey == "salutationNone"){
                        console.log(action.payload)
                        const salutationNone : TemplateParagraph = {
                            paragraphKey: "salutationNone",
                            rawValue: action.payload.output.paragraph,
                            htmlValue: "",
                            literals: [

                            ],
                            variables: [{
                                placeholder: "sponsor.name"
                            }]
                        }
                        salutationNone.htmlValue = convertToHtml(salutationNone)
                        state.paragraphs!.salutationNone = salutationNone

                        const tempTempOutput = action.payload.output.paragraph
                        console.log(tempTempOutput)
                        tempTempOutput.replace("[", "")
                        tempTempOutput.replace("]", "")

                        state.aioutput!.salutationNone = tempTempOutput
                    }

                    if(action.payload.output.paragraphKey == "categories"){
                        console.log(action.payload)
                        const categories : TemplateParagraph = {
                            paragraphKey: "categories",
                            rawValue: action.payload.output.paragraph,
                            htmlValue: "",
                            literals: [
                                {
                                    value: state.literals!.event_name
                                },
                                {
                                    value: state.literals!.event_categories[0]
                                },
                                {
                                    value: state.literals!.event_categories[1]
                                },
                            ],
                            variables: []
                        }
                        categories.htmlValue = convertToHtml(categories)
                        state.paragraphs!.categories = categories

                        const tempTempOutput = action.payload.output.paragraph
                        console.log(tempTempOutput)
                        tempTempOutput.replace("[", "")
                        tempTempOutput.replace("]", "")

                        state.aioutput!.categories = tempTempOutput
                    }

                    if(action.payload.output.paragraphKey == "eventInformation"){
                        console.log(action.payload)
                        const eventInformation : TemplateParagraph = {
                            paragraphKey: "eventInformation",
                            rawValue: action.payload.output.paragraph,
                            htmlValue: "",
                            literals: [
                                {
                                    value : state.literals!.event_total_participants.toString()
                                },
                                {
                                    value: state.literals!.event_total_amount.toString()
                                },
                                {
                                    value: state.literals!.event_DonationPurpose[0]
                                },
                                {
                                    value: state.literals!.event_DonationPurpose[1]
                                },
                                {
                                    value: state.literals!.event_total_distance.toString()
                                }
                            ],
                            variables: []
                        }
                        eventInformation.htmlValue = convertToHtml(eventInformation)
                        state.paragraphs!.eventInformation = eventInformation

                        const tempTempOutput = action.payload.output.paragraph
                        console.log(tempTempOutput)
                        tempTempOutput.replace("[", "")
                        tempTempOutput.replace("]", "")

                        state.aioutput!.eventInformation = tempTempOutput
                    }

                    if(action.payload.output.paragraphKey == "thanks"){
                        console.log(action.payload)
                        const thanks : TemplateParagraph = {
                            paragraphKey: "thanks",
                            rawValue: action.payload.output.paragraph,
                            htmlValue: "",
                            literals: [

                            ],
                            variables: []
                        }
                        thanks.htmlValue = convertToHtml(thanks)
                        state.paragraphs!.thanks = thanks

                        const tempTempOutput = action.payload.output.paragraph
                        console.log(tempTempOutput)
                        tempTempOutput.replace("[", "")
                        tempTempOutput.replace("]", "")

                        state.aioutput!.thanks = tempTempOutput
                    }


                    if(action.payload.output.paragraphKey == "roundUp"){
                        console.log(action.payload)
                        const roundUp : TemplateParagraph = {
                            paragraphKey: "roundUp",
                            rawValue: action.payload.output.paragraph,
                            htmlValue: "",
                            literals: [

                            ],
                            variables: []
                        }
                        roundUp.htmlValue = convertToHtml(roundUp)
                        state.paragraphs!.roundUp = roundUp

                        const tempTempOutput = action.payload.output.paragraph
                        console.log(tempTempOutput)
                        tempTempOutput.replace("[", "")
                        tempTempOutput.replace("]", "")

                        state.aioutput!.roundUp = tempTempOutput
                    }


                    if(action.payload.output.paragraphKey == "payment"){
                        console.log(action.payload)
                        const payment : TemplateParagraph = {
                            paragraphKey: "payment",
                            rawValue: action.payload.output.paragraph,
                            htmlValue: "",
                            literals: [

                            ],
                            variables: []
                        }
                        payment.htmlValue = convertToHtml(payment)
                        state.paragraphs!.payment = payment

                        const tempTempOutput = action.payload.output.paragraph
                        console.log(tempTempOutput)
                        tempTempOutput.replace("[", "")
                        tempTempOutput.replace("]", "")

                        state.aioutput!.payment = tempTempOutput
                    }


                    if(action.payload.output.paragraphKey == "greeting"){
                        console.log(action.payload)
                        const greeting : TemplateParagraph = {
                            paragraphKey: "greeting",
                            rawValue: action.payload.output.paragraph,
                            htmlValue: "",
                            literals: [

                            ],
                            variables: []
                        }
                        greeting.htmlValue = convertToHtml(greeting)
                        state.paragraphs!.greeting = greeting

                        const tempTempOutput = action.payload.output.paragraph
                        console.log(tempTempOutput)
                        tempTempOutput.replace("[", "")
                        tempTempOutput.replace("]", "")

                        state.aioutput!.greeting = tempTempOutput
                    }




                    // set the loading state back to idle
                    state.status = "idle";
                    state.paragraphLoading = ""
                })
                .addCase(updateTemplate.rejected, (state) => {

                    //notify the user when the backend couldnt create a valid value
                    notifications.show({
                        title: 'Fehler beim Generieren',
                        message: 'Beim Generieren des Paragraphen ist ein unerwarteter Fehler aufgetreten',
                    });
                    state.paragraphLoading = ""
                    state.status = "failed";
                });

            builder
                .addCase(generateTemplate.pending, (state)=> {
                    state.status = "loading"
                } )
                .addCase(generateTemplate.fulfilled, (state,action)=> {
                    console.log(action.payload)

                    //parse the response to an handable object
                    const tempOutput : Template = JSON.parse(action.payload.aiOutput)

                    //initialize the paragraph
                    const salutationFemale : TemplateParagraph = {
                        paragraphKey: "salutationFemale",
                        rawValue: tempOutput.salutationFemale,
                        htmlValue: "",
                        literals: [],
                        variables: [{
                            placeholder: "sponsor.name"
                        }]
                    }

                    //update the the textParagraph value
                    salutationFemale.htmlValue = convertToHtml(salutationFemale)


                    //repeat for every Paragraph
                    const salutationMale : TemplateParagraph = {
                        paragraphKey: "salutationMale",
                        rawValue: tempOutput.salutationMale,
                        htmlValue: "",
                        literals: [],
                        variables: [{
                            placeholder: "sponsor.name"
                        }]
                    }
                    salutationMale.htmlValue = convertToHtml(salutationMale)


                    const salutationNone : TemplateParagraph = {
                        paragraphKey: "salutationNone",
                        rawValue: tempOutput.salutationNone,
                        htmlValue: "",
                        literals: [],
                        variables: [{
                            placeholder: "sponsor.name"
                        }]
                    }
                    salutationNone.htmlValue = convertToHtml(salutationNone)


                    const categories : TemplateParagraph = {
                        paragraphKey: "categories",
                        rawValue: tempOutput.categories,
                        htmlValue: "",
                        literals: [
                            {
                                value: state.literals!.event_name
                            },
                            {
                                value: state.literals!.event_categories[0]
                            },
                            {
                                value: state.literals!.event_categories[1]
                            },
                        ],
                        variables: []
                    }
                    categories.htmlValue = convertToHtml(categories)


                    const eventInformation : TemplateParagraph = {
                        paragraphKey: "eventInformation",
                        rawValue: tempOutput.eventInformation,
                        htmlValue: "",
                        literals: [
                            {
                                value : state.literals!.event_total_participants.toString()
                            },
                            {
                                value: state.literals!.event_total_amount.toString()
                            },
                            {
                                value: state.literals!.event_DonationPurpose[0]
                            },
                            {
                                value: state.literals!.event_DonationPurpose[1]
                            },
                            {
                                value: state.literals!.event_total_distance.toString()
                            }
                            ],
                        variables: []
                    }
                    eventInformation.htmlValue = convertToHtml(eventInformation)


                    const thanks : TemplateParagraph = {
                        paragraphKey: "thanks",
                        rawValue: tempOutput.thanks,
                        htmlValue: "",
                        literals: [],
                        variables: []
                    }
                    thanks.htmlValue = convertToHtml(thanks)

                    const roundUp : TemplateParagraph = {
                        paragraphKey: "roundUp",
                        rawValue: tempOutput.roundUp,
                        htmlValue: "",
                        literals: [],
                        variables: []
                    }
                    roundUp.htmlValue = convertToHtml(roundUp)

                    const payment : TemplateParagraph = {
                        paragraphKey: "payment",
                        rawValue: tempOutput.payment,
                        htmlValue: "",
                        literals: [],
                        variables: []
                    }
                    payment.htmlValue = convertToHtml(payment)

                    const greeting : TemplateParagraph = {
                        paragraphKey: "greeting",
                        rawValue: tempOutput.greeting,
                        htmlValue: "",
                        literals: [],
                        variables: []
                    }
                    greeting.htmlValue = convertToHtml(greeting)

                    //update the TemplatePargraphs with all the above initialized Paragrpahs
                    state.paragraphs = {
                        salutationFemale: salutationFemale,
                        salutationMale: salutationMale,
                        salutationNone: salutationNone,
                        categories: categories,
                        eventInformation: eventInformation,
                        thanks: thanks,
                        roundUp: roundUp,
                        payment: payment,
                        greeting: greeting
                    }

                    //make it to a string to remove all "[" and "]"
                    const tempTempOutput = JSON.stringify(tempOutput)
                    tempTempOutput.replace("[", "")
                    tempTempOutput.replace("]", "")

                    //Make it to an object again and update the state that is used to communicate to other systems
                    state.aioutput = JSON.parse(tempTempOutput)

                    //set loading back to idle
                    state.status = "idle"
                } )
                .addCase(generateTemplate.rejected, (state)=> {

                    //notify the user when the backend couldnt create a valid value (won't happen here ;) )
                    notifications.show({
                        title: 'Fehler beim Generieren',
                        message: 'Beim Generieren der Vorlage ist ein unerwarteter Fehler aufgetreten',
                    });
                    state.status = "failed"
                } )
        }
    }
)

export const selectTemplate = (state : RootState)=> state.templateState
export const { setConfigGlobal, setTemplateForm, updateParagraph, setLiterals, setLoadingParagraph, setEventId } = templateSlice.actions;

export default templateSlice.reducer;
