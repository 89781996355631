import {
    Button,
    Divider,
    Paper,
    Select,
    Slider,
    Textarea,
} from "@mantine/core";
import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../hooks";
import {generateTemplate, setConfigGlobal} from "../State/templateState";

import {selectTemplate} from "../State/templateState";

export interface Configuration {
    polite: number;
    salutation: string;
    weather: string;
}

export enum Salutation {
    Sie = "Sie",
    Du = "Du",
}

export enum Weather {
    Sonnig = "Sonnig",
    Schnee = "Schnee",
    Regen = "Regen",
}

export const PrompConfiguration = () => {

    //be able to update the redux store
    const dispatch = useAppDispatch();

    //be able to read the redux store
    const stateTemplate = useAppSelector(selectTemplate);

    //Überbleibsel aus Stefans anforderungen. Ja ich erwähne dich namentlich weil ich das damals schon für mist gehalten habe.
    const variables: string[] = []

    //local State for keeping track of the configuration changes
    const [config, setConfig] = useState<Configuration>({
        polite: 2,
        salutation: Salutation.Sie,
        weather: Weather.Sonnig,
    });


    //render the configuration panel
    return (
        <div
            style={{
                minWidth: "300px",
                paddingRight: "20px",
                paddingLeft: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            {
                //User input slider for politness (kind of pointless in the whole use case)
            }
            <h4>Höflichkeit</h4>
            <Slider
                style={{width: "100%"}}
                min={1}
                max={5}
                value={config.polite}
                onChange={(e) =>
                    setConfig({
                        ...config,
                        polite: e,
                    })
                }
            />

            {
                //User ipnut for the Salutation (this one is actually making sense)
            }
            <h4>Anrede</h4>
            <Select
                style={{width: "100%"}}
                data={[Salutation.Sie, Salutation.Du]}
                value={config.salutation}
                onChange={(e) =>
                    setConfig({
                        ...config,
                        salutation: e!,
                    })
                }
            ></Select>

            {
                //User Input for the Weather during the event
            }
            <h4>Wetter</h4>
            <Select
                style={{width: "100%"}}
                data={[Weather.Sonnig, Weather.Regen, Weather.Schnee]}
                value={config.weather}
                onChange={(e) => {
                    setConfig({
                        ...config,
                        weather: e!,
                    })
                    let configTemp : Configuration = {
                        salutation: config.salutation,
                        polite: config.polite,
                        weather: e!,
                    }
                }

                }
            ></Select>

            {
                //gotta update the config twice, because there are still technical schulden because of steffans beschissene Anforderungen. if you dont setConfigGlobal then after the first request the config state is forgotten and partially generating textparagraphs will create errors
            }
            {stateTemplate.variables !== undefined && stateTemplate.status !== "loading" && <Button
                style={{marginLeft: "auto", marginTop: "20px"}}
                onClick={() => {
                    let literals = stateTemplate.literals!
                    dispatch(setConfigGlobal(config))
                    dispatch(generateTemplate({config, variables, literals}))
                }

                }
            >
                Vorlage Generieren
            </Button>}

            {
                //if its loading the button will be rendered disabled
                stateTemplate.variables !== undefined && stateTemplate.status === "loading" && <Button
                disabled
                style={{marginLeft: "auto", marginTop: "20px"}}
                onClick={() => {
                    let literals = stateTemplate.literals!
                    dispatch(generateTemplate({config, variables, literals}))
                }

                }
            >
                Vorlage Generieren
            </Button>}
        </div>


    );
};