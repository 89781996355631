import {AppShell, Box, Text, Group, UnstyledButton, Tabs, rem} from "@mantine/core"
import {useState} from "react"
import {useAppSelector} from "../hooks"
import {PrompConfiguration} from "./PrompConfiguration"
import {TemplateController} from "./TemplateController";

import {LiteralInput} from "./LiteralInput";
import {useDisclosure} from '@mantine/hooks';

import {selectTemplate} from "../State/templateState";
import {IconPhoto, IconSettings} from "@tabler/icons-react";
import {PdfPreview} from "./PdfPreview";
import {MoveBackendBaseUrl} from "../Arch/urls";
import {notifications} from "@mantine/notifications";

export const Dashbord = () => {

    //State from redux
    const templateState = useAppSelector(selectTemplate)

    //Navbar left side local state => init is Edit SpendenbriefVorlage
    const [selectedPage, setSelectedPage] = useState<number>(1)

    //iconstyle it is
    const iconStyle = {width: rem(12), height: rem(12)};

    //pdfurl state => setting to "" while renedering new so ui state will be rendered fresh
    const [pdfUrl, setPdfUrl] = useState<string>('');

    //function to fetch the pdf fom the deerstone system => Haram here because its NOT implemented like the architectures idea is, but because the url isnt changing its easier to handle the state in here. its working just fine, but this could be refactored into the arqitectures idea :)
    const fetchPdf = async () => {
        setPdfUrl("");

        //set keys to the Schnittstellendefinition with deerstone (MAYBE WE GOTTA TOUCH THIS TODAY TO MAKE THE INTEGRATION WORK!!!!!!!)
        // I think it did not work as some of the parrameters are set incorrectly.
        const body: {} = {
            ...templateState.aioutput, ...{
                eventId: templateState.eventId,
                eventInformation: templateState.aioutput!.eventInformation
            }
        }
        console.log(JSON.stringify(createMoveObject(removeBrackets(body))))
        const request: RequestInit = {
            method: "POST",
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                Accept: "application/octet-stream",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(createMoveObject(removeBrackets(body))) //removebrackets function removes all the "[" and "]" before sending the template to the charity move backend
        }
        console.log(JSON.stringify(body))
        console.log(body.valueOf())
        try {

            //updating the PDF
            const response = await fetch(MoveBackendBaseUrl + "invoice/template/ai", request)

            //setting the new pdf url
            if (response.ok) {
                const pdfBlob = await response.blob() // Get blob from response
                const url = URL.createObjectURL(pdfBlob); // Create URL for the blob
                setPdfUrl(url); // Set the URL to state
            }
        } catch (error) {

            //showing user feedback when there was an error while generating the pdf
            notifications.show({
                title: 'Fehler beim Pdf Generieren',
                message: '',
            });
            console.error('Error fetching PDF:', error);
        }
    };

    return <AppShell navbar={{width: 300, breakpoint: 'sm'}}>
        <AppShell.Navbar style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", background: "#111B21"}}>
            <AppShell.Section mt="xs">
                <Box>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingLeft: "20px"
                    }}>
                        <img src={require("../Arch/deerstone.jpeg")} height="40px" alt={""}/>
                        <h4 style={{marginLeft: "15px", color: "#E9EDEF"}}>
                            Move AI
                        </h4>
                    </div>
                </Box>
            </AppShell.Section>
            <AppShell.Section grow mt="md">
                <Box>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        paddingLeft: "20px"
                    }}>
                        <h4 style={{color: "#E9EDEF"}}>
                            {templateState.literals?.event_name}
                        </h4>
                        <div style={{borderBottom: "1px solid #3E3E42"}}>
                            <UnstyledButton
                                style={{color: "#E9EDEF"}}
                                onClick={() => {
                                    setSelectedPage(1)
                                }}
                            >
                                Spendenbrief Vorlage
                            </UnstyledButton>
                        </div>
                    </div>
                </Box>
            </AppShell.Section>
        </AppShell.Navbar>
        <AppShell.Main>
            {
                selectedPage === 0 && <div>
                    Landing Page
                </div>
            }
            {
                selectedPage === 1 && <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    marginTop: "50px",
                    border: "1px solid lightgray",
                    marginLeft: "50px",
                    marginRight: "50px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 17px 0px",
                    overflow: "hidden"
                }}>

                    <Tabs defaultValue="1" style={{}}>
                        <Tabs.List>
                            <Tabs.Tab value="1" leftSection={<IconSettings style={iconStyle}/>}>
                                Spendbrief Erstellung
                            </Tabs.Tab>
                            <Tabs.Tab value="2" leftSection={<IconPhoto style={iconStyle}/>} onClick={() => {
                                fetchPdf()
                            }}>
                                Spendenbrief Abnehmen
                            </Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="1" style={{}}>
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <PrompConfiguration/>
                                <TemplateController/>
                            </div>
                            <div style={{width: "100%", borderTop: "1px solid lightgrey"}}>
                                <LiteralInput></LiteralInput>
                            </div>
                        </Tabs.Panel>

                        <Tabs.Panel value="2">
                            <PdfPreview pdfUrl={pdfUrl}></PdfPreview>
                        </Tabs.Panel>
                    </Tabs>
                </div>
            }
        </AppShell.Main>
    </AppShell>
}


//helper function to remove all "[" and "]" in any object. obviously created by chatgpt but its working just fine
function removeBrackets(obj: any): any {
    if (typeof obj === 'object' && obj !== null) {
        if (Array.isArray(obj)) {
            // If the input is an array, iterate through its elements
            for (let i = 0; i < obj.length; i++) {
                // Recursively call the function for nested objects
                obj[i] = removeBrackets(obj[i]);
            }
        } else {
            // If the input is an object, iterate through its key-value pairs
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    // Recursively call the function for nested objects
                    obj[key] = removeBrackets(obj[key]);
                }
            }
        }
    } else if (typeof obj === 'string') {
        // If the input is a string, remove "[" and "]" from the string
        obj = obj.replace(/\[/g, '').replace(/\]/g, '');
    }
    // Return the modified object
    return obj;
}

/*
    {
    "salutationFemale":"Sehr geehrte Frau sponsor.name,",
    "salutationMale":"Sehr geehrter Herr sponsor.name,",
    "categories":"Wir möchten Ihnen herzlich für Ihre Unterstützung beim erfolgreichen Sponsorenlauf für das Event
    Clausthal Run danken. Die Kategorien, in denen gelaufen wurde, waren Studenten und Professoren.",
    "eventInformation":"Ihre Spenden für die Zwecke ChatGptPremium und LucidChartPremium haben dazu beigetragen,
    dass insgesamt 200 Teilnehmer eine Strecke von 50 Kilometern zurückgelegt haben, wofür ein Gesamtbetrag von
    1000 Euro gesammelt wurde.",
    "thanks":"Wir möchten uns bei Ihnen herzlich für Ihre großzügige Unterstützung bedanken, besonders an
    diesem sonnigen Tag.",
    "roundUp":"Wir möchten Sie dazu ermutigen, Ihren Beitrag zu unserem erfolgreichen Event aufzurunden,
    um noch mehr Gutes zu tun.",
    "payment":"Bitte nutzen Sie den QR-Code unten, um Ihre Spende zu bezahlen und den Erfolg des Events
    weiter zu unterstützen.",
    "greeting":"Nochmals vielen Dank für Ihre Unterstützung. Wir freuen uns schon auf das nächste Event.",
    "eventId":"49552"}
*/
const InvoiceTextVo = (obj:any) => {
    return {
        "eventId": obj.eventId,
        "salutation": obj.salutationMale,
        "categories" : obj.categories,
        "eventInformation": obj.eventInformation,
        "thanks" : obj.thanks,
        "roundUp" : obj.roundUp,
        "payment" : obj.payment,
        "greeting" : obj.greeting,
    };
};
const InvoiceSalutationVo = (eventId: any, salutation: any, text: any) => {
    return {
        "eventId" : eventId,
        "salutation": salutation,
        "salutationText": text,
    };
};
// Function for future use to dynamically get the correct Salutation depending on the saluation Key.
const getSalutation = (salutationKey: any) => {
    switch (salutationKey) {
        case("salutationFemale"):
            return "MRS";
        case("salutationMale"):
            return "MR";
        case("salutationNone"):
            return "NONE";
        case("salutationCompany"):
            return "COMPANY";
        case("salutationFamily"):
            return "FAMILY";
        default:
            return null;
    }
}
// If no saluation is given we have some default saluations (Maybe this should be safed in the move backend...)
const defaultSalutationFriendly = {
    MRS: "Liebe [sponsor.name]",
    MR: "Lieber [sponsor.name]",
    NONE: "Hallo [sponsor.name]",
    COMPANY: "Liebes Team von [sponsor.name]",
    FAMILY: "Liebe Faimilie [sponor.name]"
};

const defaultSalutationPolite = {
    MRS: "Sehr geehrte Frau [sponsor.name]",
    MR: "Sehr geehrte Herr [sponsor.name]",
    NONE: "Guten Tag [sponsor.name]",
    COMPANY: "Sehr geehrtes Team von [sponsor.name]",
    FAMILY: "Sehr geehrte Faimilie [sponor.name]"
};



// Function to create a fitting move object:
function createMoveObject(obj: any): any {
    // object also return default values
    //  refactor texts to a i18n save place
    const salutationTypes = ["MRS", "MR", "NONE", "COMPANY", "FAMILY"];
    const invoiceTextVo = InvoiceTextVo(obj);

    return {invoiceTextVo: invoiceTextVo,
    salutations :{
        MRS: {eventId: obj.eventId, salutationText: obj.salutationFemale, salutation: "MRS"},
        MR: {eventId: obj.eventId, salutationText: obj.salutationMale, salutation: "MR"},
        NONE: {eventId: obj.eventId, salutationText: obj.salutationNone, salutation: "NONE"},
        FAMILY: {eventId: obj.eventId, salutationText: defaultSalutationFriendly.FAMILY, salutation: "FAMILY"},
        COMPANY: {eventId: obj.eventId, salutationText: defaultSalutationFriendly.COMPANY, salutation: "COMPANY"}
    }};

}